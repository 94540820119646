.custom-table .ant-table {
    /* background-color: red; */
}

.custom-table .ant-table-header tr th {
    background-color: var(--color-primary) !important;
    /* Custom background color for header */
}

.custom-table .ant-table .ant-table-thead th {
    color: var(--light);
    background-color: var(--color-primary) !important;
    /* Custom background color for header */

}

.custom-table .ant-table-cell {
    /* Styles for table cells */
    color: #333;
    /* Text color for cells */
}

.custom-table .ant-table,
.custom-table .ant-table td,
.custom-table .ant-table th {
    font-size: 12px;
    /* Adjust the font size as needed */
}

.paginated-table-button {
    font-size: 12px;
    /* Adjust the font size as needed */
}