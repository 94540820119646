.model-con{
    position: fixed;
    left: 0;
    right: 0;top: 0;
    bottom: 0;
    background-color: rgba(189, 189, 189, 0.9);
    z-index: 3;
}
.model-box{
    background-color: white;
    position: fixed;
    left: 50%;
    padding: 50px;
    top: 50%;
    transform: translate(-50%,-50%);
}
.model-box::-webkit-scrollbar {
    width: 6px; /* Adjust the width as needed */
  }
  
  .model-box::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 3px; /* Rounded corners of the scrollbar thumb */
  }