.addtocart-button-container{
    width: 7rem;
    height: 2rem;
    border: 0.18rem solid #f6b51b;
    background-color: white;
    border-radius: 0.28rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.addtocart-link{
    text-decoration: none;
    color: #181818;
    font-size: 1rem;
    font-weight: 600;
}

.addtocart-button-container:hover{
    transform: translateY(2px);
    width: 7rem;
    height: 2rem;
    border: none;
    background-color: #f6b51b;
    border-radius: 0.28rem;
}


