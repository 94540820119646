.signup-button-container{
    height: 2rem;
    width: 7rem;
    border: none;
    border-radius: 0.28rem;
    background: #f6b51b;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    
}

.signup-button-link{
    color: #181818;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem
}
.signup-button-container{
    text-decoration: none;
}

.signup-button-container:hover{
    border: 0.18rem solid #f6b51b;
    background-color: white;
    border-radius: 0.28rem;
    cursor: pointer;
    transform: translateY(2px);
}

