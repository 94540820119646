.trending-products-container{
    padding: 3.5rem 2rem;

    background-color: transparent;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.trending-products-heading{
    margin-bottom: 2rem;
    color: #000000;
    font-family: 'Krona One', sans-serif; 
    font-weight: 400;
}

.chair-images-container{
    width: 100%;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: start;
}

.coloured-chair-image{
    /* margin: 0 2rem; */
    height: auto;
    /* width: 11rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
}

.star-icon-review{
    color: #f6b51b;

}

.view-more-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-more-link{
    text-decoration: none;
    color: #000000;
    border-bottom:2px solid #000000;

}



.arrow-icon-view-more{
    font-size:1.7rem;
}

@media screen and (max-width:768px){
    .chair-images-container{
       flex-direction: column ;
    }
    .trending-products-container{
        height: auto;
    }
}