.login-admin-box{
    width: 600px;
    border: 1px solid grey ;
    position: absolute;
    top: 40%;
    left: 50%;
    box-shadow: 0px 0px 7px grey;
    transform: translate(-50%,-50%);
    padding: 50px;
}
.login-admin-con{
    position: relative;
    height: 70vh;
}

.all-placeholder-grey::placeholder{
    color: rgb(51, 49, 49);
  
  }
.all-placeholder-grey{
    border:1px solid rgb(51, 49, 49);
  
  }