.you-may-also-like-container{
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}

.you-may-also-like-container h1{
    font-family: 'Krona One', sans-serif;
    text-align: center;
}

@media screen and (max-width: 768px){
    
}