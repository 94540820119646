.model-con{
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
transform: translate(-50%,-50%);
z-index: 1002;
}
.model-box{
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 700px;
    padding: 20px;
}

@media screen and (max-width:600px){
    .model-box{

        width: 90vw;
    }
  }