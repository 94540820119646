.accordion2-container{
    padding: 1rem 3rem;
}

.accordion2-options{
    display: flex;
    gap: 1.5rem;
    color: #333333;
    margin-bottom: 2rem;
}


.accordion2-option{
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
}

.accordion2-option.active{
    font-weight: 600;
}

.accordion2-content p{
    font-size: 0.8rem;
    font-family: "Poppins", sans-serif;
}

.reviews-container-product-details-page{
    width: 100%;
    height: auto;
    padding: 1rem 2rem;
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem; */

}

.reviews-card-product-details-page{
    width: 100%;
    height: auto;
    background-color: transparent;
    border-radius: 2rem;
    padding: 1.5rem 0 0 0;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 0 20px 4px #ddd; */
    gap: 0.7rem;
    border: 2px solid #f6b51b;
}

.name-rating-product-details-page{
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding:0 1rem 1rem 1rem;
}

.slick-arrow.slick-next::before{
    position: absolute;
    right: 0;
}

.name-time-inner-div{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.name-time-inner-div p{
    color: #6C6C6C;
    font-size: 0.7rem;
    font-family: "Poppins", sans-serif;

}

.name-time-inner-div h3{
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
}

.rating-inner-div{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.3rem;
    background-color: #f6b51b;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;

}

.horizontal-line-reviews-product-details-page-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.horizontal-line-reviews-product-details-page{
    height: 2px;
    width: 95%;
    background-color: #ddd;
}

.reviews-content-product-details-page{
    text-align:justify;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.reviews-content-container-product-details-page{
    width: 100%;
    padding: 0.7rem 2rem 2rem 2rem;
    background-color: #f6b51b;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    height: 100%;
}

.headline-review-container-product-details-page{
    text-align: center;
    padding: 0 2rem;
    font-family: "Poppins", sans-serif;
    width: 100%;

}

.headline-review-container-product-details-page h3{
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 1.5rem;
    width: 100%;
}

.edit-remove-buttons-container{
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    margin-top: 1rem;
}

.edit-button-reviews{
    width: 33%;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    border: none;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    
}

.remove-button-reviews{
    width: 33%;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    border: none;
    background-color: red;
    cursor: pointer;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
}

/* new */
.write-review-button-container{
    display: flex;
    justify-content: center;
}
.review-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 999; /* Ensure it appears above other content */
    display: flex;
    justify-content: center;
    align-items: center;

}

.create-review-form {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    border: 2px solid #f6b51b;
    position: relative;
    width: 50%;
    
    
}
.star-icons-review-container{
    margin-bottom:20px  ;
    display: flex;
    justify-content: center;
}
.stars-given-review{
    color: #f6b51b !important;
    margin-right:20px ;
    font-size: 30px;
}
.stars-not-given-review{
    color: #f6b51b !important;
    margin-right:20px ;

    font-size: 30px;

}
/* 

.create-review-form form{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    align-items: center;
} */

/* .create-review-form form button{
    width: 30%;
    padding: 0.4rem;
    background-color: #f6b51b;
    border: none;
    font-family: "poppins", sans-serif;
    font-size: 1.2rem;
    color: white;
    border-radius: 1rem;
    cursor: pointer;
} */
/* 
.create-review-form form label{
    display: flex;
    gap: 1rem;
    align-items: baseline;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
}

.create-review-form form label input{
    width: 100%;
    padding: 0.2rem 1rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    border-radius: 1rem;
    border: 2px solid #f6b51b;
}

 */

.close-review-form {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: large;
    font-family:"Poppins", sans-serif;
    font-weight: 600;
    color: white;
    text-decoration: underline;
}

.star-icons-reveiw-container{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.7rem;
}

.stars-given-review{
    color: #f6b51b;
}

.stars-not-given-review{
    color: transparent;
}

.write-review-button {
    background-color: #f6b51b;
    color: white;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
}

/* Additional styling for the blurred background */
/* .accordion2-container {
    filter: blur(4px); /* Adjust the blur amount as needed */
/* } */ 



@media screen and (max-width: 768px){
    .reviews-container-product-details-page{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.5rem;
        padding: 0;

    }
    .reviews-card-product-details-page{
        width: 50%;
    }

    .create-review-form{
        width: 80%;
    }

    .create-review-form form label{
        font-size: 1.2rem;
    }
    .create-review-form form label input{
        font-size: 1.2rem;
    }

}

