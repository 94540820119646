.product-page-container{
    width: 100%;
    display: flex;
}

.right-product-page{
    display: flex;
    width: 100%;

    flex-direction: column;
    /* align-items: end; */
}

.sidebar {
    border-radius: 0.7rem;
    margin: 3rem;
    width: 20rem;
    padding: 0.5rem 1rem;
    background-color: #f8f2e4;
    height: auto;
}

.sidebar-container {
    position: sticky;
    top: 0px;
    /* new changes */
        height: calc(100vh - 100px); 
        overflow-y: auto; /*for separate scrolling in the sidebar*/
        overflow-x: hidden; /*for separate scrolling in the sidebar*/
    width: 100%;
    margin-right: -2rem;
  }

.sidebar-container::-webkit-scrollbar {
    width: 8px; 
  }
  
  /* Handle for the scrollbar */
  .sidebar-container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
  }
  
  /* Track for the scrollbar */
  .sidebar-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
  }
  
.filters-button {
    display: none;
    background-color: #F6B51B;
    color: #fff;
    border: none;
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 1rem;
    cursor: pointer;
}



.sidebar.show {
    left: 0;
}


.selected-options-header{
    font-family: 'Krona One', sans-serif;
    display: flex;
    margin: 0 0 1rem 0;
    justify-content: space-between;
}

.clear-all-button{
    border: none;
    font-size: 0.65rem;
    background-color: transparent;
    cursor: pointer;
}

.clear-all-button:hover{
    text-decoration: underline;

}

.selected-options {

    margin-bottom: 20px;
}

.selected-option-group {
    margin-bottom: 10px;
}

.selected-option-list {
    display: flex;
    flex-wrap: wrap;
}

.selected-option {
    background-color: #F6B51B;
    border-radius: 0.5rem;
    padding: 0.2rem 0.25rem; 
    margin: 0 0.5rem 0.5rem 0;
    display: inline-flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 0.65rem;
    font-weight: 600;
    color: #ffffff;
}

.deselect-option {
    margin-left: 5px;
    cursor: pointer;
}

.filter-heading {
    font-weight: bold;
    margin: 2rem 0 1rem 0;
    font-family: 'Krona One', sans-serif;
}

.filter-options {
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.options {
    margin-bottom: 0.5rem;
    list-style: none;
    font-family: 'Poppins', sans-serif ;
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 2.5rem; 
    padding: 1rem;
    width: 100%;
    cursor: pointer;
    margin-bottom: 0.5rem;
    list-style: none;
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-size: 0.95rem;
    transition: background-color 0.2s ease;

}

.option input{
    cursor: pointer;
}


.option:hover{
    background-color:#F6B51B;
    color:#ffffff ;
    font-size: 0.9rem;
    border-left: 2px solid black;
    padding: 2rem 1rem;
}
.option-selected{
    background-color:#F6B51B;
    color:#ffffff ;
    font-size: 1.05rem;
    border-left: 2px solid black;
}

.White-color-circle{
    color: white;
    border-radius: 50%;
    background-color: white;
}

.black-color-circle{
    color: black;
    border-radius: 50%;
    background-color: black;
}
.blue-color-circle{
    color: blue;
    border-radius: 50%;
    background-color: blue;
}
.brown-color-circle{
    color: brown;
    border-radius: 50%;
    background-color: brown;
}
.purple-color-circle{
    color: purple;
    border-radius: 50%;
    background-color: purple;
}
.grey-color-circle{
    color: grey;
    border-radius: 50%;
    background-color: grey;
}
.orange-color-circle{
    color: orange;
    border-radius: 50%;
    background-color: orange;
}
.green-color-circle{
    color: green;
    border-radius: 50%;
    background-color: green;
}
.pink-color-circle{
    color: pink;
    border-radius: 50%;
    background-color: pink;
}
.cyan-color-circle{
    color: cyan;
    border-radius: 50%;
    background-color: cyan;
}

.show-more-option{
    cursor: pointer;
    color: black;
    margin-left: 2rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.3rem;
    font-weight: 200;
}

.sort-by-featured-container{
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 200;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0.2rem;
    margin: 0 5rem -2rem 3.5rem;
    width:10rem;
    border-radius: 0.7rem;
    background-color: #F8F2E4;
    color: #2E2E2E;
}

.grid-product-page-container{
    margin-left: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
}

.carousel-chair-card-product-page{
    text-decoration: none;

    border-radius: 1.2rem;
    background-color: #f2f2f2;
    transition: box-shadow 0.1s ease-in;
}

.carousel-chair-card-product-page:hover{
    box-shadow: 0 0 10px gray
}

.carousel-chair-image-product-page{
    width: 100%;
    height: auto;
    border-radius: 1.2rem 1.2rem 0rem 0rem;
}

.carousel-card-uptext-product-page{
    font-size: 1.5rem;
    font-weight: 400;
    color: black;
    font-family: 'Poppins', sans-serif;
}  

.span-price-product-page{
    text-decoration: line-through;
    font-size: 0.8rem;
    font-weight: 400;
    color: #969696;
    font-family: 'Poppins', sans-serif;
}

.carousel-card-price-product-page{
    width: 100%;
    font-size: 0.83rem;
    font-weight: 600;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    display: flex;
    gap: 0.4rem;
    align-items: center;
}

.discount-rate-product-page{
    font-size: 0.7rem;
    font-weight: 600;
    color: #fa2d2d;
    font-family: 'Poppins', sans-serif;
}

.carousel-card-bottom-container-product-page{
    padding: 0rem 0.4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    align-items: center;
}


.first-line-product-page{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rating-box-product-page{
    display: flex;
    align-items: center;
    gap: 0.1rem;
    justify-content: center;
    background-color: #F6B51B;
    border-radius: 1rem;
    padding: 0.1rem 0.15rem;
    font-size: 0.65rem;
    color: white;
}

.search-bar-product-page{
    margin-bottom: 4rem ;
    width: 25rem;
    padding: 0.5rem 1rem;
    background-color: #f8f2e4;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;

}

.search-bar-product-page input{
    width: 100%;
    border: none;
    background-color: transparent;
    color:#AEAEAE;

}

.search-icon-product-page{
    color: #F6B51B;
}






@media screen and (max-width: 768px){
    .product-page-container{
        width: 100%;
        display: flex;
        gap: 2rem;
        /* justify-content: center; */
    }

    .left-side-product-page{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        position: absolute;
        
    }

    .grid-product-page-container{
        width: 100%;
        margin-left: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
        row-gap: 1rem;
    }

    .sort-by-featured-container{
        display: none;
    }

    
    .carousel-chair-card-product-page{
        text-decoration: none;
        /* width: 11rem; */
        border-radius: 1rem;
        background-color: #f2f2f2;
    }

    .filters-button {
        display: block;
    }

    .filters-button-container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
    }

      .sidebar {
        margin-left: 1rem;
        position: fixed;
        z-index: 1000;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        width: 15rem;
        height: 100vh;
        left: -100rem;
        transition: left 0.3s ease;
        border-right: none;
        border-radius: 1rem;

        
      }
    .show{
        display: block;
    }
    .not-show{
        display: none;
    }
      .sidebar.show {
        left: 0;
        position: fixed;
        top: 0;
        border-radius: 1rem;
        margin-top: 0;
        padding-top: 2rem;
      }

      .right-product-page{
        width: 100%;
        padding: 4rem 1rem ;
      }

      .search-bar-product-page{
        width: 80%;
        margin-bottom: 0;
      }

      .search-bar-product-page input{
        font-size: 0.9rem;
      } 

      .sidebar-container{
        width: 16.5rem;
        padding-right: 0;
        position: absolute;
        margin-top: 0;
        top: 0%;
        border-radius: 1rem;
       
      }

      .search-bar-filters-button-container{
        width: 100%;
        display: flex;
        gap: 4rem;
        justify-content: center;
        align-items: center;
      }


    
}