.address-page-main-part-container{
    display: flex;
    padding: 3rem ;
    justify-content: center;
    align-items: start;
    gap: 1.5rem;
}

.address-page-main-part-left-container{
    width: 65%;
    background-color: transparent;

}

.address-page-main-part-left-container p{
    padding: 0 0.5rem 1rem 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
}

.address-page-main-part-left-first-box{
    width: 100%;
    background-color: #F8F2E4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: 600;    
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.address-page-add-new-address{
    border: none;
    background-color: #f6b51b;
    color: white;
    padding: 0.2rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.15s ease;
}

.address-page-add-new-address:hover{
    background-color: white;
    color: #f6b51b;
}

.address-page-main-part-address-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.address-page-main-part-address-item {
    width: 100%;
    display: flex;
    align-items: start;
    padding: 1rem;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0.5rem;
    border: 1px solid #f6b51b;
}

.address-page-main-part-address-item:hover {
    background-color: #F8F2E4;
    border: none;
}

.address-page-main-part-address-item.selected .address-page-main-part-bullet {
    background-color: #f6b51b;
}

.address-page-main-part-bullet {
    flex-shrink: 0;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #f6b51b;
    margin-top: 0.2rem;
    margin-right: 0.7rem;
}

.address-page-main-part-address-details {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;    
    font-family: "Poppins", sans-serif;

}

.address-page-main-part-address-name{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
}

.address-page-main-part-address-text, .address-page-main-part-address-pincode{
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.9rem;
}
.address-page-main-part-address-text b, .address-page-main-part-address-pincode b{
  margin-left: 20px;
}

.address-page-main-part-address-mobile{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
}

.address-page-main-part-address-mobile span{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
}

.address-page-main-part-buttoons-container{
     display: flex;
     align-items: center;
     gap: 0.3rem;
}

.address-page-main-part-buttoons-container button{
    width: 6rem;
    border: 1.5px solid #f6b51b;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    padding: 0.05rem 0.5rem;
    font-size: 0.7rem;
    border-radius: 0.2rem;
    transform: background-color 1s ease;
    cursor: pointer;
}

.address-page-main-part-buttoons-container button:hover{
    background-color: #f6b51b;
    color: white;
    font-weight: 600;
}

.address-page-main-part-list-header{
    padding: 0 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
}

.address-page-main-part-right-container{
    width: 35%;
    background-color: transparent;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.address-page-main-part-right-container p{
    font-weight: 900;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}

.address-page-main-part-right-first-box{
    padding: 0.5rem 2rem ;
    display: flex;
    gap: 2rem;
    align-items: center;
    background-color: #F8F2E4;
    border-radius: 0.5rem;
}

.address-page-main-part-right-second-box{
    background-color: #F8F2E4;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem ;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.address-page-main-part-right-first-box p{
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
}
.address-page-main-part-right-first-box span{
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
}

.address-page-main-part-right-line-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-page-main-part-right-vertical-line{
    height: 4rem;
    width: 1px;
    background-color: #f6b51b;
}

.address-page-main-part-right-horizontal-line{
    height: 1px;
    width: 100%;
    background-color: #f6b51b;
}

.address-page-right-prices-container{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.address-page-right-prices-first{
    display: flex;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}

.address-page-right-prices-first span{
    font-weight: 600;
}

.address-page-right-prices-second{
    display: flex;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}

.address-page-right-prices-second span{
    font-weight: 600;
    color: #3DA58A;
}

.address-page-right-prices-third{
    display: flex;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}

.address-page-right-prices-third span{
    font-weight: 600;
}

.address-page-right-place-order-link{
    border: none;   
    background-color: #f6b51b;
    color: white;
    width: 100%;
    padding:0.5rem  2rem;
    text-align: center;
    border-radius: 2rem;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    transform: background-color 0.2s ease;
    margin-bottom: 0.6rem;
}

.address-page-right-place-order-link:hover{
    background-color: white;
    color: #f6b51b;
}

@media screen and (max-width:768px){
    .address-page-main-part-container{
        display: flex;
        flex-direction: column;
        padding: 3rem ;
        justify-content: center;
        align-items: start;
        gap: 1.5rem;
    }

    .address-page-main-part-left-container{
        width: 100%;
        background-color: transparent;
    
    }
    .address-page-main-part-right-container{
        width: 100%;
        background-color: transparent;
        
        margin-top: 2rem;
    }
}


