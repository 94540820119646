.related-products-container{
    padding: 4rem 16rem 0rem 16rem;
    
}

.related-products-container h1{
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 400;
    font-family: "Krona One", sans-serif;
}

.grid-product-page-container2{
    display: flex;
    gap: 3rem;
}

@media screen and (max-width: 768px){
    .related-products-container{
        padding: 4rem;
    }
    .grid-product-page-container2{
        display: flex;
        gap: 1rem;
    }
}