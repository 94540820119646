.main-section-profile-page-container{
    
    padding: 0 13rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
}

.profile-page-main-part-left-second-box{
    width: 100%;
    display: flex;
}

.profile-page-main-part-details{
    width: 40rem;
}

.profile-page-main-sidebar{
    background-color: #F8F2E4;
    padding: 1rem 0;
    width: 30%;
    /* height: 56vh; */
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.right-container-main-section-profile-page{
    position: relative;
    padding: 0.8rem 1.2rem;
    width: 100%;
    background-color: white;
    height: 45vh;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid #f6b51b;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.right-container-main-section-profile-page span h4{
    font-family: 'Poppins', sans-serif;
}

.right-container-main-section-profile-page span h2{
    font-family: 'Poppins', sans-serif;
}

.profile-image-main-section-profile-page{
    position: absolute;
    top: 4%;
    right: 2%;
    background-color: #D6D6D6;
    padding: 0.5rem 0.5rem 0 0.5rem;
}

.info-main-sectin-profile-page p{
    font-family: 'Poppins', sans-serif;
}

.info-main-sectin-profile-page p span{
    font-weight: 600;
}

.right-container-main-section-profile-page button{
    width: 17%;
    padding: 0.3rem 1rem;
    background-color: transparent;
    text-align: center;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    border:1px solid #f6b51b;
    border-radius: 0.5rem;
    transition: background-color 0.1s ease;
}

.right-container-main-section-profile-page button:hover{
    background-color: #f6b51b;
    color: white;
    cursor: pointer;
}

.order-details-profile-page-container{
    padding: 0.8rem 1.2rem;
    width: 100%;
    background-color: white;
    /* height: 65vh; */
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid #f6b51b;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.order-details-profile-page-container h4{
    font-family: 'Poppins', sans-serif;
}

.order-details-profile-page-container ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem ;
}

.order-details-profile-page-container ul li{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
}

.order-details-profile-page-container ul li p{
    font-family: 'Poppins', sans-serif;
    color: #3DA58A;
    font-weight: 600;
    font-size: 0.8rem;
}

.each-order-detail-profile-page{
    width: 100%;
    background-color: #F8F2E4;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    padding: 0.3rem 1rem ;
    align-items: center;
    border-radius: 0.6rem;
}

.order-detail-profile-page-inner-div{
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: start;
}

.vertical-line-each-order-detail-profile-page{
    height: 7rem;
    width: 1.5px;
    background-color: #f6b51b;
}

.product-details-each-order-detail-profile-page{
    display: flex;
    flex-direction: column;
    gap: 0rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;

}

.product-details-each-order-detail-profile-page span{
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 0.8rem;
    font-weight: 400;
}

.buttons-container-each-order-detail-profile-page{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0.7rem 0;
}

.buttons-container-each-order-detail-profile-page button{
    padding: 0rem 1.4rem ;
    font-family: 'Poppins', sans-serif;
    width: 7rem;
    background-color: transparent;
    border: 1px solid #f6b51b;
    border-radius:0.5rem;
    transition: background-color 0.15s ease;
}
.buttons-container-each-order-detail-profile-page button:hover{
    cursor: pointer;
    background-color: #f6b51b;
    color: white;   
}

.payment-page-main-part-left-first-box{
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
}

.payment-page-main-part-left-first-box span{
   font-family: 'Poppins', sans-serif;
   font-weight: 400;
   font-size: smaller;
}



/* font-family: 'Poppins', sans-serif; */

@media screen and (max-width:768px){
    .main-section-profile-page-container{
        padding: 3rem ;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right-container-main-section-profile-page{
        width: 28rem;
        height: auto;
        padding-bottom: 3rem;
        border-radius: 0.5rem;
    }
    
    .right-container-main-section-profile-page button{
        width: 6rem;
        font-size: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .order-details-profile-page-container{
        width: 28rem;
        height: auto;
        padding-bottom: 3rem;
        border-radius: 0.5rem;
    }

    .buttons-container-each-order-detail-profile-page button{
        width: 5rem;
        font-size: 0.5rem ;
    }

    .profile-page-main-part-details{
        width: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .profile-page-main-sidebar{
        display: flex;
        width: 100%;
        height: auto;
        padding: 0;
        border-radius: 0.5rem;
        
    }
    .profile-page-main-sidebar div{
        display: flex;
       
        
    }

    .profile-page-main-part-left-second-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

}
