.payment-page-main-part-container{
    padding: 3rem;
    display: flex;
    gap: 1.5rem;
}

.payment-page-main-part-left-container{
    width: 65%;
    background-color: transparent;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.payment-page-main-part-left-first-box{
    width: 100%;
    padding: 1rem;
    font-weight: 900;
    background-color: #F8F2E4;
    border-radius: 0.5rem;
}

.payment-page-main-part-left-second-box{
    display: flex;
}

.payment-page-main-part-left-sidebar{
    background-color: #F8F2E4;
    padding: 1rem 0;
    width: 30%;
    height: 56vh;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.payment-page-main-part-left-sidebar-option{
    padding: 0.7rem 0.9rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 1rem 0;
    color: black;
    transition: background-color 0.15s ease;
}

.payment-page-main-part-left-sidebar-option:hover{
    background-color: #f6b51b;
    color: white;
    cursor: pointer;
    border-left: 2px solid black;

}
.payment-page-main-part-left-sidebar-option-active{
    background-color: #f6b51b;
    padding: 0.7rem 0.9rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 1rem 0;
    color: white;
    cursor: pointer;
    border-left: 2px solid black;

}

.payment-page-main-part-payment-details{
    width: 40rem;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem 2rem ;
    border: 1.5px solid #f6b51b;
    border-top: none;
    border-bottom-right-radius: 0.5rem;
}

.payment-page-main-part-payment-details input{
    margin-left: 1rem ;
    border: 1px solid #f6b51b;
    padding: 0.6rem 2rem ;
    font-size: 1rem ;
    border-radius: 0.5rem;
}

.payment-page-main-part-left-sidebar-input-format h3{
    font-size: 1.5rem ;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 2.4rem 2rem 0rem 2rem;
    border: 1.5px solid #f6b51b;
    border-bottom: none;
    border-top-right-radius: 0.5rem ;
    

}


.bank-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap:0rem;
}
  
.bank-list li {
    font-family: 'Poppins', sans-serif;
    padding: 0.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.bank-list li.selected {
    background-color: transparent;
}

.bank-list li.selected .payment-page-main-part-disc-icon{
    background-color: #f6b51b;
    box-shadow: 
    inset 0 0 0 1.5px white, /* Inset shadow for inside color */
    0 0 0 1px #f6b51b; /* Outset shadow for outside color */

}


.payment-page-main-part-disc-icon{
    border: 1px solid #f6b51b;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.payment-page-paynow-button-container{
    display: flex;
    align-items: center;
    justify-content: center;

}

.payment-page-main-part-pay-now{
    border: none;
    background-color: #f6b51b;
    color: white;
    padding: 0.3rem 0;
    width: 50%;
    margin-bottom: 0rem;
    margin-top: -2rem;
    border-radius: 0.5rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.15s ease;
}

.payment-page-main-part-pay-now:hover{
    background-color: white;
    color: #f6b51b;
    border: 1px solid #f6b51b;
    cursor: pointer;
}

.payment-page-main-part-right-container{
    width: 35%;
    background-color: #F8F2E4;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    padding: 1rem;
    height: 27vh;
}

.payment-page-main-part-right-container p{
    font-weight: 900;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}