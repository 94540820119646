.OtherCategoriesShopByCategory-container{
    height: auto;
    width: 100%;
    padding: 3rem;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.important-dabba{
    display: flex;
    justify-content: center;
}

.OtherCategoriesShopByCategory-container h1{
    font-family: 'Krona One', sans-serif; 
    margin-bottom: 2rem;

}

.OtherCategoriesShopByCategory-container ul{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
    justify-content: center;
    align-items: center;
}

.OtherCategoriesShopByCategory-container ul li{
    list-style: none;
    /* height: 21rem; */
    /* width: 19rem; */
    background-color: #F8F2E4;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    transition: background-color 0.3s ease;
}

.OtherCategoriesShopByCategory-container ul li:hover{
    background-color: #f6b51b;
}

.OtherCategoriesShopByCategory-container ul li a{
    width: 100%;
    text-decoration: none;

}


.OtherCategoriesShopByCategory-container ul li img{
    border-radius: 0.5rem;
    /* height: 16rem; */
    width: 100%;
}

.OtherCategoriesShopByCategory-container ul li p{
    text-align: center;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin: 1rem;
}

.nina-campbell-quote{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 8rem;
}

.nina-campbell-quote img{
    margin-bottom: 1.5rem;
}

.nina-campbell-quote p{
    font-weight: 200;
    font-size: 1.7rem;
    text-align: center;
}

.nina-campbell-quote h2{
    margin-top: 0.7rem ;
    font-style: italic;
}

@media screen and (max-width:768px){
    .OtherCategoriesShopByCategory-container ul{
        grid-template-columns: repeat(2, 1fr);
    }

    .OtherCategoriesShopByCategory-container ul li{
        /* height: 16rem; */
        /* width: 14rem; */
    }

    .nina-campbell-quote{
        padding: 1rem;
    }

    .nina-campbell-quote img{
        margin-top: 3rem ;
    }

}