.navbar-user-buttons{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 2rem;
}

@media screen and (max-width:960px){
    .navbar-user-buttons{
        display: none;
    }
}
