.product-page-link-container{
    padding: 1rem 3rem;
    width: 25rem;
    
}

.direction-product-page-link{
    font-family: 'Krona One', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;
    color: black;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.direction-product-page-link h1{
    font-family: 'Krona One', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
}

.CART-address-payment-container{
    display: flex;
    align-items: center;
    gap: 0.15rem;
    justify-content: center;
    color: black;
    font-size: 1.1rem;
}

.CART-address-payment-container span{
    color: #3da58a;
}

.connectivity-icon{
    display: flex;
    align-items: center;
}