*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}
:root{
  --pr-text-color:#f6b51b;
  --color-primary:#35363a;
  --color-dark:#f6b51b;
  --white:#ffffff;
  --light:#ffffff;
--black:#000000;
}

@media screen and (max-width:980px){
  html{
    font-size: 80%;
  }
}
@media screen and (max-width:760px){
  html{
    font-size: 72%;
  }
}

@media screen and (max-width:365px){
  html{
    font-size: 63%;
  }
}

.body-width{
  width: 800px;
  margin: auto;
}
.body-width-admin{
  width: 80%;
  margin: auto;
}


/* .df-jc-ac{
  display: flex;
  justify-content: center;
  align-items: center;
} */


.universal-button-atom{
  /* padding: 6px 25px; */
  padding-left: 1rem;
  position: relative;
  background-color: transparent;
  font-weight: 500;
  background-color: var(--pr-text-color);
  color: white;
  font-size: 16px;
  height: 100%;
  border-radius:unset;
  cursor: pointer;
  border: 1px solid var(--pr-text-color) ;
}
.universal-button-atom:hover{
color: var(--pr-text-color)!important;
background-color: white;
border: 1px solid var(--pr-text-color)!important ;

transition: all 0.3s ease;
}

/* In your stylesheet or inline styles */
.custom-input {
  border: 1px solid #ccc; /* Set the default border */
  outline: none; /* Remove the default focus outline */
  /* Add any other styling you'd like */
}
.custom-input:focus {
  border: none; /* Remove the border when input is focused */
  /* Add any other styling for focused state */
}
