.main-details-cart-page-container{
    padding: 3rem 2rem;
    display: flex;
    gap: 1rem;
}

.cart-page-left-container{
    padding:0 0.6rem 1rem 0.6rem;
    width: 65%;
    background-color: transparent;
    border-radius: 0.45rem;
    
}
.for-coupon{
    width: 35%;

}
.cart-page-left-first-box{
    background-color: #F8F2E4;
    width: 100%;
    padding: 0.7rem .5rem;
    border-radius: 0.6rem;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.enter-pin-code-link{
    text-decoration: none;
    background-color: #f6b51b;
    color: white;
    padding: 0.2rem 0.2rem;
    border-radius: 1rem;
    font-weight: 500;
    font-family: "Poppins", sans-serif;

}

.cart-page-left-second-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0rem;
}

.cart-page-remove-wishlist{
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    color: #7c7c7c;
    padding-right: 1rem;
}

.cart-page-left-icon-selected{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.cart-page-left-check-icon{
    font-size: 1.2rem;
    color: #f6b51b;
}

.cart-page-left-third-box{
    border: 1px solid #f6b51b;
    border-radius: 0.6rem;
    padding: 1rem 0.8rem;

}

.cart-page-left-third-table-headings{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;
    padding-bottom: 0.4rem ;
}

.cart-page-left-third-table-rest ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.cart-page-left-third-table-rest ul li{
    list-style: none;
    font-family: "Poppins", sans-serif;
    padding-right: 1rem;
    
}

.seperating-line-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.seperating-line{
    
    background-color: #f6b51b;
    height: 1px;
    width: 100%;
}

.cart-page-left--item{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #F8F2E4;
    margin:1rem 0;
    border-radius: 0.6rem;

    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}
.cart-page-left--item:hover{
    transform: scale(1.01);
    transition: all 0.2s ease;

}
.p--cross--custom{
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    background: red;
    color: white;
    padding: 5px;
    display: flex;
    vertical-align: middle;
    font-size: 12px;
    border-radius: 360px;
}
.p--cross--custom span svg{
 
    color: white;
  
}
.cart-page-left-item-first-part{
    position: relative;
    display: flex;
    align-items: start;
    gap: 1.1rem;

}

.cart-page-left-third-check-icon{
    color: #f6b51b;
    font-size: 1.3rem;
    position: absolute;
    top: 0;
    left: 0;
}

.cart-page-left-item-name{
    display: flex;
    flex-direction: column;
}

.cart-left-page-item-image{
    height: 5rem;
}

.cart-page-left-vertical-line-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-page-left-vertical-line{
    height: 5rem;
    background-color: #f6b51b;
    width: 1px;
}

.cart-page-left-item-name{
    padding-top:0.6rem ;
    font-family: 'Krona One', sans-serif;
    font-weight: 600;
}

.cart-page-left-item-name span{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    color: #888888;
}

.cart-page-left-item-second-part{
    
    padding-right: 0.7rem;
    display: flex;
    gap: 6.7rem;
}

.cart-page-left-item-second-part p{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.9rem;

}

.cart-page-left-subtotal-container{
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 1rem 1rem 0.1rem 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    
}

.cart-page-left-subtotal p{
    font-family: "Poppins", sans-serif;
}

.cart-page-right-container{
    padding: 1rem 0.6rem;
    width: 35%;
    background-color: #F8F2E4;
    border-radius: 0.45rem;
    height: 20.3rem;
}
.cart-page-right-container-coupon{
    padding: 1rem 0.6rem;
    background-color: #F8F2E4;
    border-radius: 0.45rem;
    margin: 10px 0px;
    cursor: pointer;
    position: relative;
}

.cart-page-right-container h3{
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.cart-page-right-prices-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cart-page-right-prices-first{
    font-family: "Poppins", sans-serif;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;

}

.cart-page-right-prices-first span{
    font-weight: 600;
    font-size: 0.9rem;

}

.cart-page-right-prices-second{
    font-family: "Poppins", sans-serif;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;


}

.cart-page-right-prices-second span{
    color: #3DA58A;
    font-weight: 600;
    font-size: 0.9rem;

}

.cart-page-right-prices-third{
    font-family: "Poppins", sans-serif;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
}

.cart-page-right-prices-third span{
    font-weight: 600;
    font-size: 1.1rem;
}

.cart-page-right-place-order-link{
    width: 100%;
    padding: 0.7rem 5rem;
    text-align: center;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    background-color: #f6b51b;
    color: white;
    border-radius: 2rem;
    transition: background-color 0.15s ease;
    
}

.cart-page-right-place-order-link:hover{
    background-color: white;
    color: #f6b51b;
}

@media screen and (max-width: 768px){
    .main-details-cart-page-container{
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
    }
    .cart-page-left-container{
        width: 100%;
    }
    
    .cart-page-left-item-first-part{
        flex-direction: column;
        justify-content: center;
    }
    .cart-page-left-vertical-line-container{
        display: none;
    }
    .cart-page-left-item-name{
        padding: 0 0.5rem;
    }
    .cart-page-right-container{
        width: 100%;
    }
    .for-coupon{
        width: 100%;

    }
}