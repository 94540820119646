.tracker-bar-container{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.15rem;
    justify-content: center;
    color: black;
    font-size: 0.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 1rem;
}

.tracker-bar-connectivity-icon{
    display:flex;
    align-items: center;
    justify-content: center;
}

.tracker-bar-connectivity-icon span{
    height: 3px;
    width: 5rem;
    background-color: #f6b51b;
}

/* .tracker-bar-horizontal-line-uncovered{
    background-color: #888888;
    height: 3px;
    width: 5rem;
} */

.tracker-bar-circle{
    color: #f6b51b;
}

/* .tracker-bar-circle-uncovered{
    color: #888888;
} */

@media screen and (max-width:768px){

    .tracker-bar-container{
        font-size: 0.6rem;
    }

    .tracker-bar-connectivity-icon span{
        height: 3px;
        width: 2rem;
        background-color: #f6b51b;
    }
    
    .tracker-bar-horizontal-line-uncovered{
        background-color: #888888;
        height: 3px;
        width: 2rem;
    }
}

