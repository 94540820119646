.we-also-sell-container{
    margin-top: 1rem;
    padding: 0 2rem;
    /* height: 55vh; */
    width: 100%;
    background-color: transparent;
    margin-bottom: 4rem;
}

.we-also-sell-heading{
    color: #000000;
    font-family: 'Krona One', sans-serif; 
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.slick-slide > div{
    margin: 0 10px;
}

.slick-arrow.slick-next::before{
    color: #000000;
    background-color: transparent;
    border-radius: 50%;
}
.slick-arrow.slick-prev::before{
    color: #000000;
    background-color: transparent;
}



.carousel-chair-card{
    border-radius: 1.2rem;
    background-color: #f2f2f2;
}
.carousel-chair-card:hover{
    cursor: pointer;
}

.carousel-chair-image{
    width: 100%;
    height: auto;
}

.carousel-card-uptext{
    font-size: 0.8rem;
    font-weight: 600;
    color: #969696;
    font-family: 'Poppins', sans-serif;
}

.carousel-card-downtext{
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 0.9rem;
}   

.span-price{
    text-decoration: line-through;
    font-size: 0.75rem;
    font-weight: 400;
    color: #969696;
    font-family: 'Poppins', sans-serif;
}

.carousel-card-price{
    font-size: 0.78rem;
    font-weight: 600;
    color: #e34a4a;
    font-family: 'Poppins', sans-serif;
}

.carousel-card-bottom-container{
    padding: 0.6rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plus-image-container{
    cursor: pointer;
    background-color: peachpuff;
    padding: 0.5rem;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus-image{
    height: auto;
    width: 78%;
}

@media screen and (max-width: 768px) {
    .we-also-sell-container{
        height: 33vh;
    }
    
}