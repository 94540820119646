.emojis-part-container{
    padding: 3rem 7rem;
}

.emojis-part-container ul{
    display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: center;
}

.emojis-part-container ul li{
    display: flex;
    gap: 1rem;
    
}

.first-icon-container{
    padding: 01rem 1.15rem;
    background-color: #d2f3fa;
    border-radius: 0.2rem;
}

.second-icon-container{
    padding: 0.7rem 0.8rem;
    background-color: #defdd9;
    border-radius: 0.2rem;

}

.third-icon-container{
    padding: 0.7rem 0.8rem;
    background-color: #e3d6fc;
    border-radius: 0.2rem;

}

.emojis-part-container ul li p{
    font-weight: 600;
}

@media screen and (max-width:760px){
    .emojis-part-container{
        padding: 3rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .emojis-part-container ul{
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    
    .first-icon-container{
        padding: 0.5rem ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .second-icon-container{
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .third-icon-container{
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .people123{
        height: 50%;
    }
    .smileplus{
        height: 50%;
    }
    .reminder{
        height: 50%;
    }

}