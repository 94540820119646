.footer-sue-container{
    margin-top: 8rem;
    height: auto;
    width: 100%;
    background-color: transparent;
    padding: 1rem 0;
}

.footer-sue-top-part{
    padding: 0 8rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    /* column-gap: 0rem; */
    text-align: center;
    justify-content:center;
    align-items: start;
}

.footer-sue-list-heading{
    font-family: 'Actor', sans-serif;
    color: #0a142f;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 2rem 0;
}

.footer-sue-top-part ul{
    display: flex;
    flex-direction: column;   
}

.footer-sue-top-part ul li{
    margin: 0.5rem 0;
    list-style: none;
    color: #0a142f;
    font-family: 'Actor', sans-serif;
    font-weight: 200;
}

.subscribe-footer-sue-container{
    margin-top: 1rem;
    padding: 3rem;
    height: 15rem;
    width: 23rem;
    background-color: #203C860D;
}

.subscribe-footer-sue-heading{
    font-family: 'Actor', sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
}

.input-wrapper-footer-sue{
    margin: 1rem 0;
    display: flex;
    align-items: center;
}

.input-footer-sue{
    padding: 0 1rem;
    height: 2.5rem;
    width: 9rem;
    border: none;
    box-shadow: 0 0 10px #ddd;
    border-radius: 0.25rem 0 0 0.25rem;
}

.arrow-box-footer-sue{
    border: none;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #0081FE;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0 0.25rem 0.25rem 0;
    cursor: pointer;
}

.subscribe-footer-sue-container p{
    font-size: 0.7rem;
    color: #0a142f;
    font-weight: 200;
}

.footer-sue-line-container{
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-sue-line{
    height: 2px;
    width: 80%;
    background-color: #dddddd;
}

.footer-bottom-part{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
}

.footer-bottom-part ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.footer-bottom-part ul li{
    font-family: 'Actor', sans-serif;
    color: #0A142F;
    list-style: none;
}



.third-column{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem
}

.footer-sue-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 0.3rem;
    border: 2px solid #dddddd;
}


@media screen and (max-width: 768px) {

    .footer-sue-container{
        height: 65vh;
        width: 100%;
        background-color: transparent;
    }

    .footer-sue-top-part{
        padding: 2rem;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        
    }
    .information-footer-sue{
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: left;

    }

    .footer-bottom-part{
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
    }
    .first-column{
        display: none;
    }

    .input-footer-sue{
        font-size: 1rem;
    }

}


.company-footer-sue li{
    cursor: pointer;
}