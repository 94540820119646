
.active {
  text-decoration: underline;
  border-bottom: 3px solid #f6b51b;
}


.header-sue{
    padding: 0rem 2.5rem; 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    
}

.buttons{
    display: flex;
    gap: 1rem;
    margin-left: 7.5rem;
}
  
.my-logo{
  position: relative;
  top: 0.5rem;
  margin: 0;
  cursor: pointer;
  right: 0.8rem;
}
  
.navbar {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
}
  
.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.fa-bars {
    color: #fff;
}
    
.nav-menu {
    margin-top: 3.5rem;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 1.5rem;
    list-style: none;
    text-align: center;
    width: 40rem;
    justify-content: center;
    /* margin-right: 2rem; */
}

.nav-item {
    display: flex;
    align-items: center;
    height: 2rem;
}
    
.nav-links{
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
    position: relative;
    cursor: pointer;
}

.nav-links::before{
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #f6b51b;
    transition: all 0.3s;
    border-radius: 12%;
}


.nav-links:hover::before {
    width: 100%;
}

.menu-icon {
    display: none;
}
  
.nav-links-mobile {
    display: none;
}
  
    @media screen and (max-width:960px) {

      .active {
        text-decoration: none;
      }

      .buttons{
        display: none;
      }
      .NavbarItems {
          position: relative;
        }
        .header{
          padding: 0rem;
        }
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 90vh;
          position: absolute;
          top: 8.9rem;
          left: -100%;
          opacity: 1;
          /* transition: all 0.0001s ease; */
        }

        .nav-item{
          height: auto;
        }
      
        .nav-menu.active {
          background: #f8f2e4;
          padding-top: 3.8rem;
          gap: 3rem;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: start;
          top: -3rem;
          left: 0;
          opacity: 1;
          transition: all 0.2s ease-in-out;
          z-index: 2;
          text-align: center;
          
        }
      
        .nav-links {
          position: absolute;
          text-align: center;
          padding: 0.5rem;
          width: 100%;
          display: table;
          font-size: 2rem;
        }
      
        .nav-links:hover {
          background-color: white;
          border-radius: 0;
        }
      
        .my-logo {
            position: relative;
            top: 0.5rem;
            margin: 0;
            cursor: pointer;
            right: 0.8rem;
        }
      
        .menu-icon {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.8rem;
          cursor: pointer;
        }
      
        /* .fa-times {
            margin-right: 0.25rem;
          margin-top: 0.8rem;
          color: #333333;
          font-size: 2rem;
          z-index: 3;
        } */
        
        .fas.fa-times::before {
          position: absolute;
          top: 0; /* Adjust top position as needed */
          right: 1rem; /* Adjust right position as needed */
          color: #333333;
          font-size: 2rem;
          z-index: 100; /* Increase the z-index to ensure it appears above the nav-menu */
        }
        .menu-icon{
          z-index: 3;
        }
        .fa-bars{
          margin-top: 0.8rem;
          color: black;
          font-size: 2rem;
          z-index: 0;
        }
        .nav-links-mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 0.5rem;
          margin: 0 auto;
          /* margin-top: 7rem; */
          border-radius: 0.4rem;
          width: 40%;
          background:#f6b51b;
          text-decoration: none;
          color: #333333;
          font-size: 1.3rem;
          height: auto;
        }

        .nav-links-mobile:hover {
            background: #fff;
            color: #333333;
            border: 3px solid #f6b51b;
            transition: all 0.1s ease-in;
        }

        .nav-links.active{
            font-size: 1.8rem;
        }

        
      
        
        /* button {
          display: none;
        } */
    
    }


    .model-main-login{
      position: fixed;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 100;
  }
  .model-main-box-login{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 350px;
    padding: 10px;
    height: 600px;
    background-color: white;
}
.input-login-google{
  border-color: grey;
}
.input-login-google::placeholder{
  color: grey;
}

