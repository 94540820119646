.chairsofathumbnails-container{
    padding: 3rem;
    width: 100%;
    height: auto;
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.chairsofathumbnails-1{
    position: relative;
    /* height: 20rem; */
    /* width: 40rem; */
}

.chairsofathumbnails-1 img{
    /* height: 20rem; */
    width: 100%;
}
.chairsofathumbnails-2{
    position: relative;
    /* height: 20rem; */
    /* width:40rem; */
}
.chairsofathumbnails-2 img{
    /* height: 20rem; */
    width: 100%;
}



.chair-text123{
    color: white;
    margin: 2rem;
    font-family: 'Krona One', sans-serif; 
    font-weight: 400;
    font-size: 2rem;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.sofa-text123{
    color: black;
    margin: 2rem;
    font-family: 'Krona One', sans-serif; 
    font-weight: 400;
    font-size: 2rem;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

@media screen and (max-width:768px){
    .chairsofathumbnails-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .chairsofathumbnails-1{
        position: relative;
        /* height: 20rem; */
        /* width: 25rem; */
    }
    
    .chairsofathumbnails-1 img{
        /* height: 20rem; */
        width: 100%;
    }
    .chairsofathumbnails-2{
        position: relative;
        /* height: 20rem; */
        /* width: 25rem; */
    }
    .chairsofathumbnails-2 img{
        /* height: 20rem; */
        width: 100%
    }

}