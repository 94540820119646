.shop-by-category-container{
    margin-top: 2rem;
    height: 80vh;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
}

.shop-by-category-heading{
    font-family: 'Krona One', sans-serif; 
    font-weight: 400;
}

.shop-by-category-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
}

.shop-by-category-box{
    margin: 1.5rem 0.6rem;
    height: 16rem;
    width: 22rem;
    background-color: #f2eee6;
    border-radius: 0.7rem;
}


  @media screen and (max-width:760px){
    .shop-by-category-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .shop-by-category-box{
        margin: 1rem 0.5rem;
        height: 10rem;
        width: 15rem;
        background-color: #f2eee6;
        border-radius: 0.7rem;
    }
    .shop-by-category-container{
        height: 50vh;
    }
  }

