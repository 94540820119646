@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');


.discover-part-container{
    margin-top: 1rem;
    background-color: #f8f2e4;
    padding-left: 2rem;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 18rem;
}

.left-side-container{
    width: 40%;
    height: 100%;
    background: transparent;
}

.discover-part-heading{
    color: #000000;
    font-family: 'Krona One', sans-serif; 
    font-weight: 400;
}

.discover-part-text{
    color: #000000;
    margin: 1.4rem 0;
}

.shop-now-button-container{
    height: 3.5rem;
    width: 12rem;
    border: none;
    background-color: #f6b51b;
    border-radius: 0.35rem;
    cursor: pointer;
}


.shop-now-button-container a{
    padding: 2rem;
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;
}

.chair-image-container{
    padding:0 5rem;
}

.chair-image{
    width: auto;
    height: 30rem;
}   

@media screen and (max-width:768px){
    .discover-part-container{
        justify-content: center;
        display: flex;
        gap: 0rem;
        padding: 0;
    }
    .chair-image-container{
        padding: 0
    }
    .chair-image{
        width: auto;
        height: 20rem;
    }   
    .left-side-container{
        margin: 2rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .shop-now-button-container{
        height: 3.5rem;
        width: 12rem;
        border: none;
        background-color: #f6b51b;
        border-radius: 0.35rem;
        cursor: pointer;
    }
}