.chair-image-description-container{
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    height: auto;
}
.chair-image-description-left-container{
    background-color: #F8F2E4;
    width: 35%;
    height: auto;
    overflow: hidden;
}
.chair-image-description-right-container{
    background-color: transparent;
    width: 38%;
    height: auto;
    font-family: 'Poppins', sans-serif;
}
.chair-image-description-image{
    width: 100%;
    height: 100%;
}
.slick-arrow.slick-prev::before{
    display: none;
}
.slick-arrow.slick-next::before{
    margin-left: -8rem;
}
.slick-dots{
    color: #F5B51B;
}
.chair-image-descrition-heading-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chair-image-descrition-heading-container h1{
    color: black;
    font-family: 'Krona One', sans-serif;
    font-size: 3.5rem;
    font-weight: 400;
}
.chair-image-descrition-heading-container h1 p{
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Krona One', sans-serif;
}
.stars-container-123{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.stars-container-123 p{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.chair-image-description-star-icon{
    color: white;
    background-color: #F5B51B;
    padding: 0.2rem 1rem;
    border-radius: 0.2rem 1rem;
}
.chair-image-description-star{
    color: white;
}
.chair-image-description-price-cut{
    text-decoration: line-through;
    color: #6C6C6C;
    font-family: 'Poppins', sans-serif;
}
.chair-image-description-another-heading{
    display: flex;
    color: #000000;
    align-items: center;
    gap: 0.5rem;
    font-family: 'Poppins', sans-serif;
}
.chair-image-description-another-heading p{
    color: red;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}
.chair-image-description-choose-colour-container{
    margin-top: 1.5rem;
    font-size: 0.9rem;
    font-weight: 300;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.chair-image-description-price-whole-container{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
}

.colour-circles-container{
    display: flex;
    gap: 1rem;
    font-size: 1.6rem;
    margin-bottom: 1.8rem;
}
.green-circle{
    cursor: pointer;
    color: green;
}
.yellow-circle{
    cursor: pointer;
    color: palegoldenrod;
}
.blue-circle{
    cursor: pointer;
    color: blue;
}
.accordion-options{
    display: flex;
    gap: 1.2rem;
    font-size: 0.9rem;
    color: 333333;
    margin-bottom: 1rem;
}
.accordion-option{
    cursor: pointer;
    text-decoration: none;
}
.accordion-content{
    font-family: 'Poppins', sans-serif;
    font-size: 0.87rem;
    margin-bottom: 2rem;
}
.accordion-content ul{
    list-style: none;
}
.accordion-content li{
    font-family: 'Poppins', sans-serif;
}
.accordion-content li span{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1rem;
}
.chair-image-description-quantity{
    font-family: 'Poppins', sans-serif;
}
.quantity-selector{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.chair-image-description-dropdown{
    background-color: #F8F2E4;
    border: none;
    padding: 0.2rem 0.2rem;
    border-radius: 0.5rem;
    font-size: 1rem;
}
.chair-image-description-buttons-container{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
}
.chair-image-description-buy-now{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    color: white;
    background-color: #F5B51B;
    border: none;
    border-radius: 0.5rem;
    height: 2rem;
    width: 8rem;
    font-size: 0.96rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-decoration: none;
}
.chair-image-description-wishlist{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    color: black;
    background-color: #F8F2E4;
    border: none;
    border-radius: 0.5rem;
    height: 2rem;
    width: 8rem;
    font-size: 0.96rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}
.chair-image-description-heart{
    color: #FA2D2D;
}
@media screen and (max-width: 768px){
    .chair-image-description-container{
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        justify-content: start;
    }
    .chair-image-description-image{
        height: auto;
    }
    .chair-image-description-left-container{
        /* height: 20rem; */
        width: 90%;
    }
    .chair-image-description-right-container{
        width: 85%;
    }
}