.savor-comfort-container{
    padding: 1rem 2rem;
    display: flex;
    gap: 3rem;
}

.longdesk-image{
    height: 27rem;
}

.savor-comfort-right-side{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.savor-comfort-right-side-heading{
    font-family: 'Krona One', sans-serif; 
    color: #35363a;
}

.sleek-sofas{
    font-family: 'Krona One', sans-serif; 
    color: #615352;
}

.savor-comfort-right-side ul{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 1rem;
    margin-top: 2rem;
}
.savor-comfort-right-side li{
    font-weight: 700;
}
.savor-comfort-right-side li::marker{
    color: #f6b51b;
}

@media screen and (max-width:760px){
    .longdesk-image{
        height: 14rem;
    }
    .savor-comfort-container{
        align-items: center;
        flex-direction: column;
    }
    .savor-comfort-right-side-heading{
        text-align: center;
    }
    .savor-comfort-right-side ul{
        margin-left: 2rem;
    }

   
}