.background-image-sofa-container{
    overflow: hidden;
    margin-bottom: 4rem;
}

.background-image-sofa{
    width: 100%;
    height: 60vh;
    /* background-image: url('/sofabg.png'); */
    object-fit: cover;
    padding: 3rem 5rem;
}

.sale-heading-red{
    color: #fa2d2d;
    font-size: 3rem;
    font-weight: 600;
}

.allsofa-text{
    font-weight: 300;
    font-size: 1.3rem;
}

.heading-numbered{
    margin-top: 1.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    font-size: 3.2rem;
}   

.shop-now-link123{
    margin-top: 0.5rem;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #000000;
    font-weight: 300;

}

@media screen and (max-width:760px){
    .background-image-sofa{
        height: 20vh;
        padding: 1rem 3rem;
        background-size: cover; /* Ensures the image covers the entire container, maintaining aspect ratio */
        background-position: center;
    }
    .sale-heading-red{
        color: #fa2d2d;
        font-size: 2.5rem;
        font-weight: 600;
    }
    .heading-numbered{
        margin-top: 0.5rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 900;
        font-size: 2.8rem;
    }  
}